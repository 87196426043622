import { createRouter, createWebHistory } from "vue-router"
import Home from "@/views/Home.vue"
import About from "@/views/About.vue"
import Services from "@/views/Services.vue"
import Clients from "@/views/Clients.vue"
import Products from "@/views/products/Products.vue"
import Product from "@/views/products/Product.vue"
import Contact from "@/views/Contact.vue"
import PageNotFound from "@/views/PageNotFound.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (sessionStorage.getItem("redirect") !== null) {
        const redirect = sessionStorage.redirect
        delete sessionStorage.redirect
        next(redirect)
      } else {
        next()
      }
    }
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/services",
    name: "Services",
    component: Services
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/products/:key",
    name: "Product",
    component: Product
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to) => {
  window.gtag("config", window.GA_MEASUREMENT_ID, {
    page_path: to.fullPath,
    send_page_view: true
  })
})

export default router
